import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    NumberField,
    Button,
    useNotify,
    useRefresh,
    DateField,
    Filter,
    FunctionField,
    useRecordContext,
    useUpdateMany,
    useListContext,
    BulkDeleteButton,
    useUpdate,
    useDelete,
    useListFilterContext,
    SelectArrayInput,
} from 'react-admin'
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { PartiallyHiddenCpfField } from '../../components/Fields';
import { PendingTransanctionStatus, PendingTransactionOriginType } from '../../models';
import RequiredChain from '../../components/RequiredChain';
import DoneIcon from '@mui/icons-material/Done';
import { formatAttendantLabel } from '../../utils';

const BulkActionButtons = () => {
    const notify = useNotify();
    const refresh = useRefresh();
    const { selectedIds, onUnselectItems } = useListContext();
    const { filterValues } = useListFilterContext();
    const [updateMany, { isLoading }] = useUpdateMany();
    const isDisabled = filterValues.status?.includes(PendingTransanctionStatus.aproved);

    const handleClick = () => {
        updateMany('chains/chainId/approve-pending-points', { ids: selectedIds }, {
            onSuccess: () => {
                notify('Pontos validados com sucesso!', {
                    type: 'info',
                });
                onUnselectItems();
                refresh();
            },
            onError: (error) => {
                notify(error && error.message, { type: 'warning' });
                refresh();
            },
        });
    }

    return (
        <>
            <Button
                startIcon={<DoneIcon />}
                label="Aprovar"
                onClick={handleClick}
                disabled={isLoading || isDisabled}
            />
            <BulkDeleteButton disabled={isDisabled} label="Reprovar" resource="chains/chainId/pending-points" />
        </>
    )
}

const AprovePointsButton = () => {
    const notify = useNotify();
    const [update, { isLoading }] = useUpdate();
    const refresh = useRefresh();
    const record = useRecordContext();
    if (!record) { return null; }
    const { status } = record;

    const doUpdate = (id: string) => {
        update('chains/chainId/approve-pending-points', { id }, {
            onSuccess: () => {
                notify('Pontos validados com sucesso!');
                refresh();
            },
            onError: (error) => {
                notify(error && error.message, { type: 'warning' });
            }
        });
    }

    return (
        <Button
            label="Aprovar"
            onClick={() => {
                doUpdate(
                    record.id,
                );
            }}
            disabled={isLoading || status === PendingTransanctionStatus.aproved}
        />
    )
}

const ReproveButton = () => {
    const notify = useNotify();
    const [deleteOne, { isLoading }] = useDelete();
    const refresh = useRefresh();
    const record = useRecordContext();
    if (!record) { return null; }
    const { status } = record;

    const handleDelete = (id: string) => {
        deleteOne('chains/chainId/pending-points', { id }, {
            onSuccess: () => {
                notify('Pontos reprovados com sucesso!');
                refresh();
            },
            onError: (error) => {
                notify(error && error.message, { type: 'warning' });
            }
        });
    }

    return (
        <Button
            label={"Reprovar"}
            onClick={() => {
                handleDelete(
                    record.id,
                );
            }}
            disabled={isLoading || status != PendingTransanctionStatus.pending}
        />
    )
}


const PendingPointsFilter = () => (
    <Filter>
        <SelectArrayInput source="status" label="Status" choices={PendingTransanctionStatus.pendingTransanctionChoices} alwaysOn />
    </Filter>
)


const PendingPointsList: React.FC = (props) =>
    <Permission permission={PERMISSIONS.PENDING_POINTS}>
        <RequiredChain>
            <List
                {...props}
                perPage={25}
                pagination={<ListPagination />}
                exporter={false}
                actions={false}
                filters={<PendingPointsFilter />}
                title="Aprovar pontos pendentes"
                sort={{ field: 'created_at', order: 'ASC' }}
            >
                <Datagrid bulkActionButtons={<BulkActionButtons />}>
                    <TextField
                        source="name"
                        label="Nome"
                        textAlign="center"
                    />
                    <PartiallyHiddenCpfField source="cpf" label="CPF" />
                    <NumberField
                        source="points"
                        label="Pontos"
                        textAlign="center"
                    />
                    <NumberField
                        source="amount"
                        label="Litros"
                        textAlign="center"
                    />
                    <NumberField
                        source="value"
                        label="Valor Total"
                        options={{ style: 'currency', currency: 'BRL' }}
                        textAlign="center"
                    />
                    <ReferenceField
                        source="fuel_id"
                        reference="fuels"
                        label="Combustível"
                        textAlign="center"
                        link={false}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <FunctionField
                        textAlign="center"
                        label="Origem"
                        render={record => PendingTransactionOriginType.translateType(record?.pending_transaction_origin)}
                    />
                    <DateField
                        label="Data de criação"
                        source="created_at"
                        textAlign="center"
                        showTime
                    />
                    {Permission({
                        permission: PERMISSIONS.LIST_ATTENDANT,
                        children: (
                            <ReferenceField
                                label="Frentista"
                                source="attendant_id"
                                reference="attendants"
                                textAlign="center"
                                link={false}
                            >
                                <FunctionField render={record => formatAttendantLabel(record)} />
                            </ReferenceField>
                        )
                    })}
                    <FunctionField
                        label="Status"
                        textAlign="center"
                        render={record => PendingTransanctionStatus.translateType(record?.status)}
                    />
                    <AprovePointsButton />
                    <ReproveButton />
                </Datagrid>
            </List>
        </RequiredChain>
    </Permission>

export default PendingPointsList;
