import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { ArrayField, Button, Datagrid, SimpleShowLayout, TextField } from 'react-admin';
import IconCancel from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { CPFField } from '../../../components/Fields';

interface AttendantWithConflictDialogProps {
  isVisible: boolean;
  isSubmitting?: boolean;
  attendants: Array<any>;
  handleClose: () => void;
  handleConfirm: () => void;
}

const AttendantWithConflictDialog: React.FC<AttendantWithConflictDialogProps> = (props) => {

  const {
    isVisible,
    isSubmitting,
    attendants,
    handleClose,
    handleConfirm,
  } = props;

  return (
    <Dialog
      open={isVisible}
    >
      <DialogTitle>Conflito entre registros</DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="textSecondary" textAlign="center">O <strong>'Identfid'</strong> já está em uso por um registro de frentista. Se você prosseguir com o cadastro, o registro abaixo será excluído e o novo registro passará a utilizar o mesmo código.</Typography>
        <SimpleShowLayout sx={{ marginTop: 2, width: '100%', padding: 0 }} record={{ id: '', attendants }}>
          <ArrayField label=" " source="attendants">
            <Datagrid bulkActionButtons={false}>
              <TextField source="identfid" label="Identfid" />
              <TextField source="name" label="Nome" />
              <CPFField source="cpf" label="CPF" />
            </Datagrid>
          </ArrayField>
        </SimpleShowLayout>
      </DialogContent>
      <DialogActions>
        <Button startIcon={<IconCancel />} disabled={isSubmitting} label="ra.action.cancel" onClick={handleClose} />
        <Button startIcon={isSubmitting ? <CircularProgress size={20} /> : <SaveIcon />} disabled={isSubmitting} label="ra.action.confirm" onClick={handleConfirm} variant="contained" />
      </DialogActions>
    </Dialog>
  );
}

export default AttendantWithConflictDialog;