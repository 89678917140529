import React, { Fragment, useState } from 'react';
import { Button, FormDataConsumer, ReferenceInput, SimpleForm, SelectInput, useNotify, FileInput, useDataProvider, SimpleShowLayout, ArrayField, Datagrid, TextField, FunctionField, useRecordContext } from 'react-admin'
import IconContentAdd from '@mui/icons-material/GetApp';
import IconCancel from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { formatCpf, getChainId } from '../lib';
import Permission from './Permission';
import { PERMISSIONS } from '../constants';
import { Box, CircularProgress, Typography } from '@mui/material';
import Papa from "papaparse";
import { isValidCPF, onlyNumbers } from '@brazilian-utils/brazilian-utils';

const ImportButton = ({ chainId, postCommitCallback }) => {

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [conflictAttendants, setConflictAttendants] = useState([]);
    const [xmlConflictAttendants, setXmlConflictAttendants] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const [showConflictList, setShowConflictList] = useState(false);

    const findAttendantsApi = (filter, xmlAttendants) => {
        setIsloading(true);
        dataProvider.getList('attendants', { filter, pagination: { perPage: null, page: 1 } })
            .then(({ data }) => {
                const withConflicts = data.filter(item => {
                    const xmlAttendant = xmlAttendants.find(xmlAtt => xmlAtt.identfid === item.identfid);
                    return xmlAttendant && xmlAttendant?.cpf !== item.cpf;
                });

                const withoutConflicts = xmlAttendants.filter(xmlAttendant => !data.find(item => item.identfid === xmlAttendant.identfid));

                if (withConflicts.length) {
                    setConflictAttendants(withConflicts);
                    setShowConflictList(true);
                    setIsloading(false);
                    if (withoutConflicts.length) {
                        importAttendantsApi(withoutConflicts);
                    }
                } else if (withoutConflicts.length) {
                    importAttendantsApi(withoutConflicts, postCommitCallback);
                } else if (!withoutConflicts.length) {
                    notify('Arquivo CSV já foi importado', { type: 'info' });
                    postCommitCallback();
                } else if (!data.length) {
                    notify('Arquivo CSV está vazio', { type: 'info' });
                    postCommitCallback();
                }
            })
            .catch(() => {
                notify('Não foi possível carregar o arquivo', { type: 'error' });
                setIsloading(false);
            });
    }

    const importAttendantsApi = (data, onSuccessCallback) => {
        dataProvider.create('attendants', { data: data.map(item => ({ ...item, chainId })) })
            .then(({ data }) => {
                notify(`O total de ${data.attendants.length} registros foram importados`, { type: 'info' });
            })
            .catch((error) => {
                notify(error.message || `Não foi possível realizar a importação do arquivo`, { type: 'error' });
            })
            .finally(() => {
                setIsloading(false);
                onSuccessCallback && onSuccessCallback();
            });
    }

    const handleFileUpload = (file) => {
        Papa.parse(file, {
            header: true,
            complete: (result) => {
                const formatedAttendants = result.data.map(item => {
                    const values = Object.values(item);
                    return { name: values[1], cpf: onlyNumbers(values[0]), identfid: values[2] };
                }).filter(row => isValidCPF(row.cpf) && row.name && row.identfid);
                setXmlConflictAttendants(formatedAttendants);
                findAttendantsApi({ identfids: formatedAttendants.map(item => item.identfid) }, formatedAttendants);
            },
        });
    };

    const getAttendantsWithConflicts = () => {
        return conflictAttendants.map(attendant => ({ ...attendant, newAttendant: xmlConflictAttendants.find(xmlConflictAttendant => xmlConflictAttendant.identfid === attendant.identfid) }));
    }

    const ActionConflictButtons = () => {
        const [isDisabled, setIsDisabled] = useState(false);

        const postCommit = () => {
            setIsDisabled(true);
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <KeepButton isDisabled={isDisabled} postCommit={postCommit} />
                <IgnoreButton isDisabled={isDisabled} postCommit={postCommit} />
            </div>
        );
    }

    const KeepButton = ({ isDisabled, postCommit }) => {
        const record = useRecordContext();
        const dataProvider = useDataProvider();
        const notify = useNotify();

        const [isLoading, setIsLoading] = useState(false);
        const [checked, setChecked] = useState(false);

        const { newAttendant, ...attendant } = record;

        const updateRequest = () => {
            setIsLoading(true);
            dataProvider.create('attendants', { id: attendant.id, data: { ...newAttendant, chainId } })
                .then(({ data }) => {
                    setChecked(true);
                    postCommit && postCommit();
                    notify('Um novo resgistro foi criado', { messageArgs: { smart_count: 1 } });
                })
                .catch(() => {
                    notify('Não possível criar um registro');
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

        return (
            <Button startIcon={isLoading ? <CircularProgress size={16} /> : <></>} disabled={isLoading || isDisabled} label="Sim" variant={checked ? 'contained' : 'outlined'} onClick={updateRequest} />
        );
    }

    const IgnoreButton = ({ isDisabled, postCommit }) => {
        const notify = useNotify();
        const [checked, setChecked] = useState(false);

        const ignoreItem = () => {
            notify('registro ignorado');
            setChecked(true);
            postCommit && postCommit();
        }

        return (
            <Button disabled={isDisabled} label="Não" variant={checked ? 'contained' : 'outlined'} onClick={ignoreItem} />
        );
    }

    return (
        <Box width="100%" marginTop={3}>
            {
                showConflictList ?
                    <>
                        <Typography variant="body1">Os registros abaixo entraram em conflito com os já existentes no banco. Escolha se deseja desvincular o registro atual para associar o 'identfid' ao novo registro importado. O registro anterior será excluído. Clique em 'Sim' para confirmar a troca pelo novo registro.</Typography>
                        <SimpleShowLayout sx={{ width: '100%', padding: 0, marginTop: 2 }} record={{ attendants: getAttendantsWithConflicts() }}>
                            <ArrayField source="attendants" label="Frentistas">
                                <Datagrid bulkActionButtons={false}>
                                    <TextField source="identfid" label="Identfid" />
                                    <FunctionField label="Nome" render={record => (
                                        <>
                                            <Typography variant="body1">Atual: {record.name}</Typography>
                                            {
                                                record.newAttendant ?
                                                    <Typography variant="body2" color="textSecondary">CSV: {record.newAttendant.name}</Typography>
                                                    :
                                                    <></>
                                            }
                                        </>
                                    )} />
                                    <FunctionField label="CPF" render={record => (
                                        <>
                                            <Typography variant="body1">Atual: {formatCpf(record.cpf)}</Typography>
                                            {
                                                record.newAttendant ?
                                                    <Typography variant="body2" color="textSecondary">CSV: {formatCpf(record.newAttendant.cpf)}</Typography>
                                                    :
                                                    <></>
                                            }
                                        </>
                                    )} />
                                    <ActionConflictButtons label="Trocar?" />
                                </Datagrid>
                            </ArrayField>
                        </SimpleShowLayout>
                    </>
                    :
                    isLoading ?
                        <Box display="flex" flexDirection="row" gap={2} width="100%" justifyContent="center">
                            <CircularProgress size={22} />
                            <Typography variant="body1" color="textSecondary">Carregando o arquivo...</Typography>
                        </Box>
                        :
                        <FileInput
                            source=" "
                            accept=".csv"
                            placeholder="Selecione um arquivo CSV"
                            onChange={(e) => handleFileUpload(e.target.files[0])}
                        />
            }
        </Box>
    );
}

export default props => {
    const notify = useNotify();
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    };

    const closeDialog = () => {
        setShowDialog(false)
    };

    return (
        <Fragment>
            <Button onClick={handleClick} label="Importar CSV">
                <IconContentAdd style={{ transform: "rotate(180deg)", fontSize: "20" }} />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={closeDialog}
                aria-label="Importar CSV"
                maxWidth="md"
            >
                <DialogTitle>Importar CSV</DialogTitle>
                <DialogContent>
                    <SimpleForm
                        // We want no toolbar at all as we have our modal actions
                        toolbar={null}
                        resource="attendants"
                        defaultValues={{ chainId: getChainId() }}
                    >
                        <Typography style={{ marginTop: 5 }}>
                            Importar uma planilha com as colunas nessa ordem, independente do nome da coluna: CPF (com ou sem máscara), Nome e identfid
                        </Typography>
                        <Permission permission={PERMISSIONS.ADMIN}>
                            <ReferenceInput
                                source="chainId"
                                reference="chains"
                                perPage={null}
                                sort={{ field: "name", order: "ASC" }}
                            >
                                <SelectInput label="Rede" optionText="name" />
                            </ReferenceInput>
                        </Permission>
                        <FormDataConsumer>
                            {({ formData }) => (
                                <ImportButton
                                    chainId={formData.chainId || getChainId()}
                                    postCommitCallback={() => {
                                        closeDialog();
                                    }}
                                />
                            )}
                        </FormDataConsumer>
                    </SimpleForm>
                </DialogContent>
                <DialogActions>
                    <Button label="ra.action.close" onClick={closeDialog}>
                        <IconCancel />
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );

}
