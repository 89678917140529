import PrintIcon from '@mui/icons-material/Print';
import { Button } from 'react-admin';
import ReactToPrint from 'react-to-print';

const PrintSummaryButton = (props) => (
  <ReactToPrint
    onBeforeGetContent={props.onClick}
    trigger={() => <Button variant="outlined" sx={{ height: 32, marginBottom: '3px' }} label="Imprimir Totais" startIcon={<PrintIcon />} />}
    content={() =>  props.reportSummaryRef.current}
  />
);

export default PrintSummaryButton;