import React from 'react';
import {
  DateField,
  TextField,
  NumberField,
  Show,
  ReferenceField,
  SimpleShowLayout,
  FunctionField,
  BooleanField,
  SelectField,
  useRecordContext,
  TopToolbar,
  EditButton,
} from 'react-admin';
import { getChainId } from '../../lib';
import { PERMISSIONS } from '../../constants';
import { FillinOrigin, FillinPresetType } from '../../models';
import { Permission } from '../../components';
import { formatAttendantLabel } from '../../utils';

const FillinTitle = () => (
  <span>{`Abastecimento`}</span>
);

const PresetValueField = ({ source }) => {
  const record = useRecordContext();

  return (
    record.presetType === FillinPresetType.cash ?
      <NumberField source={source} locales="pt-BR" options={{ style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }} />
      :
      <NumberField source={source} locales="pt-BR" emptyText="--" />
  );
}

const ShowActions = () => (
  <TopToolbar>
    <Permission permission={PERMISSIONS.EDIT_FILLIN}>
      <EditButton />
    </Permission>
  </TopToolbar>
);

const FillinShow = ({ permissions, ...props }) => (
  <Show title={<FillinTitle />} actions={<ShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      {
        Permission({
          children: <TextField source="reserveId" label="ID da Reserva" />,
          permission: PERMISSIONS.ADMIN,
        })
      }
      <NumberField
        source="amount"
        label="Quantidade em litros"
      />
      <NumberField
        source="value"
        label="Valor pago"
        options={{ style: 'currency', currency: 'BRL' }}
      />
      <NumberField
        source="hoseNumber"
        label="Número do bico"
      />
      <NumberField
        source="encerrante"
        label="Encerrante"
      />
      <ReferenceField label="Combustível" reference="fuels" source="fuelId">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Posto"
        reference="stations"
        source="placeId"
        filter={{ chainId: getChainId() }}
        link={permissions && permissions.includes(PERMISSIONS.ADMIN)}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Frentista"
        emptyText="Não identificado"
        source="attendantId"
        reference="attendants"
        link={permissions && permissions.includes(PERMISSIONS.UPDATE_ATTENDANT)}
      >
        <FunctionField render={record => formatAttendantLabel(record)} />
      </ReferenceField>
      <ReferenceField label="Cliente" reference="customers" source="customerId" link='show'>
        <TextField source="name" />
      </ReferenceField>
      <DateField
        source="createdAt"
        label="Data inclusão"
        showTime
      />
      <DateField
        source="receivedAt"
        label="Data do recebimento"
        showTime
      />
      <BooleanField
        source="considerFillingCompletely"
        label="Considerado tanque cheio?"
      />
      <FunctionField
        label="Valor do desconto concedido"
        render={record =>
          `${(record.oldPricePerLiter && record.pricePerLiter
            ? (record.oldPricePerLiter - record.pricePerLiter) *
            (record.amount || 0)
            : 0
          ).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
        }
      />
      <NumberField
        source="oldPricePerLiter"
        label="Preço por litro original"
        options={{
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 3,
        }}
      />
      <NumberField
        source="pricePerLiter"
        label="Preço por litro real"
        options={{
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 3,
        }}
      />
      <FunctionField
        source="origin"
        label="Origem"
        render={record => FillinOrigin.translateOrigin(record ? record.origin : null)}
      />
      <SelectField source="presetType" label="Preset Limite" choices={FillinPresetType.fillinPresetTypeChoices} emptyText="--" />
      <PresetValueField source="presetValue" label="Preset Valor" />
    </SimpleShowLayout>
  </Show>
);

export default FillinShow;
