import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrayField, ChipField, Datagrid, DateField, Filter, FunctionField, NumberField, ReferenceArrayField, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectArrayInput, SelectField, SelectInput, SingleFieldList, TextField, useListContext, usePermissions } from 'react-admin';
import { PERMISSIONS } from '../../constants';
import ReportList from '../../components/ReportList';
import dayjs from 'dayjs';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import ListPagination from "../../components/ListPagination";
import { Permission } from '../../components';
import { RatingPollItemType } from '../../models';
import { getChainId } from '../../lib';
import { formatAttendantLabel } from '../../utils';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
}));

const FilterTablePrint = () => {
  const { filterValues } = useListContext();
  const { permissions } = usePermissions();
  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: dayjs().toISOString() }]} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} />
      <DateField source="to" textAlign="center" label="Até" sortable={false} />
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
      <SelectField
        source="pollType"
        label="Tipo de Enquete"
        choices={RatingPollItemType.ratingPollItemTypeChoices}
      />
      <ReferenceArrayField source="stationIds" emptyText="Todos" reference="stations" label="Postos" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField source="attendantIds" emptyText="Todos" reference="attendants" label="Frentistas" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField source="fuelIds" emptyText="Todos" reference="fuels" label="Combustíveis" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      {
        permissions && permissions.includes(PERMISSIONS.ADMIN) &&
        <ReferenceField source="chainId" emptyText="Todos" reference="chains" label="Rede" sortable={false}>
          <TextField source="name" />
        </ReferenceField>
      }
    </Datagrid>
  );
};

const ListFilters = () => {
  return (
    <Filter>
      <BetweenDatesInput alwaysOn />
      <SelectInput
        source="pollType"
        label="Tipo de Enquete"
        choices={RatingPollItemType.ratingPollItemTypeChoices}
        alwaysOn
      >

      </SelectInput>
      <ReferenceArrayInput
        reference="attendants"
        source="attendantIds"
        perPage={null}
        sort={{ field: "name", order: "ASC" }}
        alwaysOn
      >
        <SelectArrayInput
          optionText={record => formatAttendantLabel(record)}
          label="Frentista"
          style={{ minWidth: 180 }}
        />
      </ReferenceArrayInput>
      {
        Permission({
          permission: PERMISSIONS.ADMIN,
          children: (
            <ReferenceInput
              source="chainId"
              reference="chains"
              perPage={false}
              sort={{ field: 'name', order: 'ASC' }}
              alwaysOn
            >
              <SelectInput label="Rede" optionText="name" emptyText="Todos" />
            </ReferenceInput>
          )
        })
      }
      <ReferenceArrayInput
        source="stationIds"
        reference="stations"
        perPage={null}
        sort={{ field: "name", order: "ASC" }}
        alwaysOn
      >
        <SelectArrayInput
          label="Posto"
          optionText="name"
          style={{ minWidth: 180 }}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="fuelIds"
        reference="fuels"
        perPage={null}
        sort={{ field: "name", order: "ASC" }}
        alwaysOn
      >
        <SelectArrayInput
          optionText={"name"}
          style={{ minWidth: 180 }}
          label="Combustíveis"
        />
      </ReferenceArrayInput>
    </Filter>
  )
}

const ReportRatingPollGroup = (props) => {
  const classes = useStyles();
  return (
    <Permission permission={PERMISSIONS.RATING_POLL_ITEMS_REPORT}>
      <ReportList
        {...props}
        basePath="report-rating-poll-group"
        title="Enquetes"
        resource="chains/chainId/reports/rating-poll-group"
        filters={<ListFilters />}
        filterTablePrint={<FilterTablePrint />}
        filterDefaultValues={{
          from: dayjs().startOf('day').subtract(1, "month").toISOString(),
          to: dayjs().endOf('day').toISOString(),
          chainId: getChainId(),
        }}
        pagination={<ListPagination />}
        perPage={25}
        isReport={false}
      >
        <>
          <Typography className={classes.title}>Enquetes</Typography>
          <Datagrid bulkActionButtons={false}>
            <FunctionField
              label="Enquete"
              render={(record) => record && record.id !== "0" ?
                <ReferenceField
                  source="id"
                  reference="rating-poll-items"
                  textAlign="center"
                  link={false}
                  sortable={false}
                >
                  <TextField source="name" />
                </ReferenceField>
                : <TextField source="name" emptyText="Todos" />
              }
            />
            <ArrayField source="attendants" label="Frentistas" sortable={false}>
              <Datagrid bulkActionButtons={false}>
                <FunctionField
                  label="Frentista"
                  render={(record) => record && record.attendantid !== "0" ?
                    <ReferenceField
                      source="attendantid"
                      reference="attendants"
                      textAlign="center"
                      emptyText="Não identificado"
                      link={false}
                      sortable={false}
                    >
                      <FunctionField render={record => formatAttendantLabel(record)} />
                    </ReferenceField>
                    : <TextField source="name" emptyText="Todos" />
                  }
                />
                <NumberField
                  source="total"
                  label="Quantidade de usos"
                  textAlign="center"
                  emptyText="0"
                  sortable={false}
                />
                <NumberField
                  source="avg_station"
                  label="Média de nota do posto"
                  textAlign="center"
                  emptyText="0"
                  options={{
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }}
                  sortable={false}
                />
                <NumberField
                  source="avg_attendant"
                  label="Média de nota dos frentistas"
                  textAlign="center"
                  emptyText="0"
                  options={{
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }}
                  sortable={false}
                />
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </>
      </ReportList>
    </Permission>
  );
}

export default ReportRatingPollGroup;