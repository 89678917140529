import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { ChipField, Datagrid, DateField, Filter, NumberField, ReferenceArrayField, ReferenceArrayInput, ReferenceField, SelectArrayInput, SingleFieldList, TextField, useListContext } from "react-admin";
import { BetweenDatesInput } from "../../components/BetweenDatesInput";
import { PartiallyHiddenCpfField } from "../../components/Fields";
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import ReportList from '../../components/ReportList';
import { PERMISSIONS } from '../../constants';
import { getChainId } from "../../lib";

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
}));

const ListAdvertisement = () => {
  const classes = useStyles();
  const props = useListContext();

  let data = [];
  let summaryData = [];

  if (props.data) {
    const fillinInfo = props.data[0];

    if (fillinInfo) {
      data = fillinInfo.data;
      summaryData = fillinInfo.summaryData;
    }
  }

  return (
    <>
      <Typography className={classes.title}>Total</Typography>
      <Datagrid data={summaryData} bulkActionButtons={false} style={{ marginBottom: 60 }}>
        <ReferenceField
          source="advertisement_id"
          reference="advertisements"
          label="Anúncio"
          sortable={false}
          link={false}
          textAlign="center"
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField label="Total de visualizações" source="cnt_views" textAlign="center" sortable={false} />
        <NumberField label="Total de clientes" source="cnt_customers" textAlign="center" sortable={false} />
      </Datagrid>
      <Typography className={classes.title}>Visualização de Anúncios</Typography>
      <Datagrid data={data} bulkActionButtons={false}>
        <ReferenceField
          source="customerId"
          reference="customers"
          label="CPF"
          sortable={false}
          link={false}
          textAlign="center"
        >
          <PartiallyHiddenCpfField source="cpf" />
        </ReferenceField>
        <ReferenceField
          source="advertisementId"
          reference="advertisements"
          label="Anúncio"
          sortable={false}
          link={false}
          textAlign="center"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="stationId"
          reference="stations"
          label="Posto"
          sortable={false}
          link={false}
          textAlign="center"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="fuelId"
          reference="fuels"
          label="Combustível"
          sortable={false}
          link={false}
          textAlign="center"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="systemName" label="Dispositivo" textAlign="center" />
        <DateField label="Data da visualização" locales="pt-BR" source="createdAt" showTime textAlign="center" />
      </Datagrid>
    </>
  );
};

const FilterAdvertisement = () => {
  return (
    <Filter>
      <BetweenDatesInput alwaysOn />
      <ReferenceArrayInput
        style={{ minWidth: 260 }}
        source="advertisementIds"
        reference="advertisements"
        perPage={null}
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ chainId: getChainId() }}
        alwaysOn
      >
        <SelectArrayInput
          label="Anúncios"
          optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        style={{ minWidth: 260 }}
        source="stationIds"
        reference="stations"
        perPage={null}
        sort={{ field: 'name', order: 'ASC' }}
        alwaysOn
      >
        <SelectArrayInput
          label="Postos"
          optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        style={{ minWidth: 260 }}
        sort={{ field: 'name', order: 'ASC' }}
        source="fuelIds"
        reference="fuels"
        perPage={null}
        alwaysOn
      >
        <SelectArrayInput
          label="Combustíveis"
          optionText="name" />
      </ReferenceArrayInput>
    </Filter>
  );
};

const FilterTablePrint = ({ attendantName }) => {
  const { filterValues } = useListContext();

  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, attendantName }]} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} />
      <DateField source="to" textAlign="center" label="Até" sortable={false} />
      <ReferenceArrayField label="Anúncios" source="advertisementIds" textAlign="center" basePath="advertisements" reference="advertisements">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField label="Postos" source="stationIds" textAlign="center" basePath="stations" reference="stations">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField label="Combustíveis" source="fuelIds" textAlign="center" basePath="fuels" reference="fuels">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  );
};


const ReportAdvertisementViewing = (props) => {
  return (
    <Permission permission={PERMISSIONS.ADVERTISEMENT_VIEWS_REPORT}>
      <ReportList
        {...props}
        basePath="report-advertisement-viwing"
        title="Visualização de Anúncios"
        resource="chains/chainId/reports/advertisement-viwing"
        perPage={25}
        sort={{ field: 'createdAt', order: 'DESC' }}
        filters={<FilterAdvertisement />}
        filterDefaultValues={{
          from: moment().startOf('day').subtract(1, "month").toISOString(),
          to: moment().endOf('day').toISOString()
        }}
        pagination={<ListPagination />}
        filterTablePrint={<FilterTablePrint />}
        bulkActionButtons={false}
        titleOnPrint="Relatório de Visualização de Anúncios"
      >
        <ListAdvertisement />
      </ReportList>
    </Permission>
  );
};

export default ReportAdvertisementViewing;
