import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  ReferenceInput,
  SelectInput,
  required,
  minLength,
  email,
  BooleanInput,
  FormDataConsumer,
} from 'react-admin'
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { makeStyles } from '@mui/styles';
import { getChainId } from '../../lib';
import { EmployeeType } from '../../models';
import { formatAttendantLabel } from '../../utils';

const useStyles = makeStyles((theme) => ({
  profiles: {
    width: 220,
  }
}));

const validateEmail = [required(), email()]
const validatePassword = [required(), minLength(6)];

export default props => {
  const classes = useStyles();
  return (
    <Permission permission={PERMISSIONS.MANAGE_ATTENDANT_USER}>
      <Create {...props} resource="employees" title="Cadastrar usuário frentista">
        <SimpleForm sanitizeEmptyValues defaultValues={{ chainId: getChainId(), type: EmployeeType.attendant }}>
          <Permission permission={PERMISSIONS.ADMIN}>
            <ReferenceInput {...props} label="Rede" source="chainId" reference="chains" sort={{ field: 'name', order: 'ASC' }} perPage={null} >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Permission>
          <TextInput source="name" label="Nome" validate={[required()]} InputProps={{ autoComplete: 'off' }} />
          <TextInput source="login" label="Login" validate={validateEmail} InputProps={{ autoComplete: 'off' }} />
          <TextInput source="password" label="Senha" validate={validatePassword} InputProps={{ autoComplete: 'new-password' }} />
          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceInput
                source="attendantId"
                reference="attendants"
                filter={{ chainId: formData.chainId }}
                //@ts-ignore
                perPage={null}
                style={{ width: 256 }}
                validate={[required()]}
              >
                <SelectInput source="name" label="Frentista" optionText={record => formatAttendantLabel(record)} />
              </ReferenceInput>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceInput
                className={classes.profiles}
                label="Perfil"
                source="profileId"
                reference="profiles"
                sort={{ field: 'name', order: 'ASC' }}
                perPage={9999}
                filter={{ chainId: formData.chainId }}
                validate={[required()]} >
                <RadioButtonGroupInput optionText="name" />
              </ReferenceInput>
            )}
          </FormDataConsumer>
          <BooleanInput source="mustChangePassword" label="Forçar usuário a trocar senha no primeiro acesso" />
        </SimpleForm>
      </Create>
    </Permission>
  )
}