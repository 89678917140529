import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Fragment, useState } from 'react';
import { useWatch } from 'react-hook-form';
import {
  Create,
  DateTimeInput,
  FormDataConsumer,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  number,
  required,
  useGetList,
  useNotify,
  useRecordContext,
} from 'react-admin';

import { getChainId } from '../../lib';
import PriceInput from '../PriceInput';

const VALUE_VALIDATION = [required(), number()]

const BalanceButton = ({ onClose }) => {
  const notify = useNotify();
  const record = useRecordContext();
  const [displayModal, setDisplayModal] = useState(true);

  const onSuccess = () => {
    notify('ra.notification.created');
    setDisplayModal(false);
    onClose();
  };

  const HoseInpunt = () => {
    const placeId = useWatch({ name: 'placeId' });
    const fuelId = useWatch({ name: 'fuelId' });

    const { data: hoseNumber, isLoading: isPendingHose, isError } = useGetList(`stations/${placeId}/hose-numbers`, { filter: { fuelId } });

    if (!isPendingHose && isError) {
      return <></>
    }

    return (
      <RadioButtonGroupInput
        source="hoseNumber"
        optionText="hoseNumber"
        label="Bicos"
        sx={{ maxWidth: 200 }}
        choices={hoseNumber || []}
        isLoading={isPendingHose}
      />
    );
  }

  return (
    <Fragment>
      <Dialog
        open={displayModal}
        onClose={() => { setDisplayModal(false); onClose(); }}
        aria-label="Adicionar saldo a um cliente"
      >
        <DialogTitle>{'Adicionar pontos para o cliente'}</DialogTitle>
        <DialogContent>
          <Create title="Adicionar saldo" resource={`chains/chainId/customers/${record.id}/balance`} mutationOptions={{ onSuccess }}>
            <SimpleForm shouldUnregister sanitizeEmptyValues>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <NumberInput
                  autoFocus
                  step={1}
                  label="Pontos"
                  source="value"
                  validate={VALUE_VALIDATION}
                />
                <TextInput
                  label="Descrição"
                  source="description"
                />
                <ReferenceInput
                  source="placeId"
                  filter={{ chainId: getChainId() }}
                  reference="places"
                  sort={{ field: 'name', order: 'ASC' }}
                  perPage={9999}
                >
                  <SelectInput
                    optionText="name"
                    label="Posto"
                  />
                </ReferenceInput>
                <ReferenceInput
                  source="fuelId"
                  reference="fuels"
                  sort={{ field: 'name', order: 'ASC' }}
                >
                  <SelectInput
                    optionText="name"
                    label="Combustível"
                  />
                </ReferenceInput>
                <FormDataConsumer>
                  {({ formData }) => {
                    return formData.fuelId && (
                      <>
                        <PriceInput source='fillinValue' label='Valor em reais' />
                        <TextInput
                          source="fillinAmount"
                          label="Quantidade"
                          format={(value) => {
                            if (!value || value === 0) {
                              return "0,00 l";
                            }
                            return `${Number(value).toFixed(2)} l`;
                          }}
                          parse={(value) => {
                            if (!value || value === '') {
                              return 0;
                            }
                            const onlyNumbers = value.indexOf('l') > -1 ? +value.replace(/[^\d]/g, '') : +value.slice(0, -2).replace(/[^\d]/g, '');
                            return onlyNumbers / 100;
                          }}
                        />
                        <HoseInpunt />
                        <NumberInput
                          source="encerrante"
                          label="Encerrante"
                        />
                        <DateTimeInput
                          source="createdAt"
                          label="Data e Hora"
                        />
                      </>
                    );
                  }}
                </FormDataConsumer>
              </div>
            </SimpleForm>
          </Create>
        </DialogContent>
      </Dialog>
    </Fragment >
  )
}

export default BalanceButton;
