import {
  ChipField,
  DateField,
  FunctionField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  WithListContext,
  useRecordContext
} from 'react-admin';
import { Permission, StarRatingField } from '../../components';
import { Box, Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { PERMISSIONS } from '../../constants';
import { formatAttendantLabel } from '../../utils';

const RatingPollItems: React.FC<{ label: string, source: string }> = ({ source }) => {
  const record = useRecordContext();

  return (
    <ReferenceArrayField
      record={{ ...record, pollItemIds: (record[source] || []).map(item => item.ratingPollItemId) }}
      source="pollItemIds"
      reference="rating-poll-items"
    >
      <WithListContext render={({ data }) => (
        (data && data.length) ?
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
          :
          <Typography variant="body2">Sem enquetes</Typography>
      )} />
    </ReferenceArrayField>
  );
}

const RatingShow = ({ permissions, ...props }) => (
  <Show title="Avaliação" {...props}>
    <SimpleShowLayout>
      <Grid container width={{ xs: '100%', xl: 1200 }} spacing={2}>
        <Grid item xs={12} md={6}>
          <Box component="fieldset" sx={{ border: '1px dashed grey' }}>
            <Legend>Detalhes</Legend>
            <SimpleShowLayout sx={{ padding: 1 }}>
              <TextField source="id" label="ID" />
              <ReferenceField source="customerId" reference="customers" label="Cliente">
                <TextField source="name" />
              </ReferenceField>
              <TextField source="comment" label="Comentário" emptyText='Sem comentário' />
              <DateField source="createdAt" label='Data' showTime />
            </SimpleShowLayout>
          </Box>
        </Grid>

        <Permission permission={PERMISSIONS.LIST_FILLINS}>
          <Grid item xs={12} md={6}>
            <Box component="fieldset" sx={{ border: '1px dashed grey' }}>
              <Legend>Abastecimento</Legend>
              <SimpleShowLayout sx={{ padding: 1 }}>
                <ReferenceField source="fillinId" reference="fillins" label="Combustível">
                  <ReferenceField source="fuelId" reference="fuels">
                    <TextField source="name" />
                  </ReferenceField>
                </ReferenceField>
                <ReferenceField source="fillinId" reference="fillins" label="Quantidade em litros">
                  <NumberField source="amount" />
                </ReferenceField>
                <ReferenceField source="fillinId" reference="fillins" label="Valor total">
                  <NumberField
                    source="value"
                    options={{ style: 'currency', currency: 'BRL' }}
                  />
                </ReferenceField>
              </SimpleShowLayout>
            </Box>
          </Grid>
        </Permission>
      </Grid>

      <Grid container width={{ xs: '100%', xl: 1200 }} spacing={2}>
        <Grid item xs={12} md={6}>
          <Box component="fieldset" sx={{ border: '1px dashed grey' }}>
            <Legend>Posto</Legend>
            <SimpleShowLayout sx={{ padding: 1 }}>
              <ReferenceField source="stationId" reference="stations" label="Posto">
                <TextField source="name" />
              </ReferenceField>
              <StarRatingField source="stationRating" label="Avaliação" />
              <RatingPollItems source="ratingStationRatingPollItems" label="Enquetes" />
            </SimpleShowLayout>
          </Box>
        </Grid>
      </Grid>

      <Grid container width={{ xs: '100%', xl: 1200 }} spacing={2}>
        <Grid item xs={12} md={6}>
          <Box component="fieldset" sx={{ border: '1px dashed grey' }}>
            <Legend>Frentista</Legend>
            <SimpleShowLayout sx={{ padding: 1 }}>
              <ReferenceField source="attendantId" reference="attendants" label="Frentista" emptyText="Não identificado">
                <FunctionField render={record => formatAttendantLabel(record)} />
              </ReferenceField>
              <StarRatingField source="attendantRating" label="Avaliação" />
              <RatingPollItems source="ratingAttendantRatingPollItems" label="Enquetes" />
            </SimpleShowLayout>
          </Box>
        </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
);

const Legend = styled('legend')({
  paddingLeft: 12,
  paddingRight: 12,
});

export default RatingShow;
