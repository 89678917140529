import { PERMISSIONS } from '../../constants';
import Permission from '../../components/Permission';
import React, { useState } from 'react'
import {
  Edit,
  TextInput,
  SimpleForm,
  required,
  ReferenceInput,
  SelectInput,
  Toolbar,
  FormDataConsumer,
  SaveButton,
  useSaveContext,
  useDataProvider,
  useNotify,
} from "react-admin";
import { getChainId } from '../../lib';
import { CPFInput } from '../../components/Inputs';
import AttendantWithConflictDialog from './AttendantWithConflictDialog';
import { useFormContext, useFormState } from 'react-hook-form';

const SaveConfirmButton = ({ formData, ...props }) => {

  const { isSubmitting } = useFormState();

  const saveContext = useSaveContext();
  const form = useFormContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [showConflictDialog, setShowConflictDialog] = useState(false);
  const [attendantsWithConflicts, setAttendantsWithConflicts] = useState<Array<any>>([]);

  const handleSaveButton = async (formData: any) => {
    setIsLoading(true);
    //@ts-ignore
    dataProvider.getList('attendants', { filter: { identfids: [formData.identfid] }, pagination: { perPage: null, page: 1 } })
      .then(({ data }) => {
        if (data.filter(item => item.id !== formData.id).length) {
          setShowConflictDialog(true);
          setAttendantsWithConflicts(data);
        } else {
          handleSubmit();
        }
      })
      .catch(() => {
        notify('Não foi possível verificar a disponibilidade do Identfid');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const handleSubmit = async () => {
    setShowConflictDialog(false);
    saveContext.save && await saveContext.save(formData);
  }

  const handleCancel = () => {
    setShowConflictDialog(false);
  }

  return (
    <>
      <SaveButton disabled={isLoading || isSubmitting} onClick={form.handleSubmit(handleSaveButton)} />
      <AttendantWithConflictDialog
        isVisible={showConflictDialog}
        attendants={attendantsWithConflicts}
        handleClose={handleCancel}
        handleConfirm={handleSubmit}
      />
    </>
  );
}

const AttendantEdit = (props) => {

  const ToolbarCustom = () => {
    return (
      <Toolbar>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return <SaveConfirmButton
              {...rest}
              formData={formData}
            />
          }}
        </FormDataConsumer>
      </Toolbar>
    );
  }

  return (
    <Permission permission={PERMISSIONS.UPDATE_ATTENDANT}>
      <Edit title='Editar frentista' {...props}>
        <SimpleForm defaultValues={{ chainId: getChainId() }} toolbar={<ToolbarCustom />}>
          <TextInput source="name" label="Nome" validate={required("Nome é obrigatório")} />
          <TextInput source="identfid" label="Identificador" validate={required("Identificador é obrigatório")} />
          <Permission permission={PERMISSIONS.ADMIN}>
            <ReferenceInput label="Rede" source="chainId" reference="chains">
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Permission>
          <CPFInput source="cpf" label="CPF" isRequired />
        </SimpleForm>
      </Edit>
    </Permission>
  );
}

export default AttendantEdit;