import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from "react";
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  FormDataConsumer,
  FunctionField,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SingleFieldList,
  TextField,
  useListContext,
  usePermissions
} from 'react-admin';
import { ReportList } from '../../components';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import ListPagination from '../../components/ListPagination';
import Permission from '../../components/Permission';
import { PERMISSIONS } from "../../constants";
import { getPlaceId } from "../../lib";
import { FillinOrigin, TagType } from "../../models";
import { formatAttendantLabel, formatNumber } from "../../utils";

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  refArrayInput: {
    minWidth: 180,
    '& .MuiFormHelperText-marginDense': {
      height: 0
    }
  }
}));

const FillinByAttendant = () => {
  const classes = useStyles();
  const props = useListContext();

  let data = [];
  let summaryData = [];

  if (props.data) {
    const reportInfo = props.data[0];

    if (reportInfo) {
      data = reportInfo.data;
      summaryData = [reportInfo.summaryData];
    }
  }

  return (
    <>
      <Typography className={classes.title}>Total</Typography>
      <Datagrid bulkActionButtons={false} style={{ marginBottom: 60 }} data={summaryData} total={1}>
        <NumberField
          source="total_value"
          label="Total em Dinheiro"
          textAlign="center"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <TextField
          source="total_fillins"
          textAlign="center"
          label="Abastecimentos no App"
          sortable={false}
        />
        <TextField
          source="outsideTheApp"
          textAlign="center"
          label="Abastecimentos fora do App"
          sortable={false}
        />
        <FunctionField
          textAlign="center"
          label="Total de abastecimentos"
          sortable={false}
          render={record => +record.total_fillins + +(record.outsideTheApp || 0)}
        />
        <NumberField
          source="total_amount"
          label="Total em Litros"
          textAlign="center"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
        <NumberField
          source="average_ticket"
          label="Ticket Médio"
          textAlign="center"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
        <NumberField
          source="average_amount"
          label="Volume Médio"
          textAlign="center"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
        <NumberField
          source="consider_filling_completely"
          label="Encheu Tanque"
          textAlign="center"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
        <FunctionField
          source="avg_attendantrating"
          label="Nota Média"
          textAlign="center"
          emptyText="0"
          render={record => formatNumber(record.avg_attendantrating, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          sortable={false}
        />
        <NumberField
          source="count_attendantrating"
          label="Total de Avaliações"
          textAlign="center"
          emptyText="0"
          sortable={false}
        />
      </Datagrid>
      <Typography className={classes.title}>Frentistas</Typography>
      <Datagrid bulkActionButtons={false} style={{ marginTop: 40 }} data={data} total={1} resource={props.resource}>
        <TextField
          source="attendant_name"
          textAlign="center"
          label="Frentista"
        />
        <TextField
          source="identfid"
          textAlign="center"
          label="Identfid"
        />
        <NumberField
          source="total_value"
          label="Total em Dinheiro"
          textAlign="center"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <TextField
          source="total_fillins"
          textAlign="center"
          label="Abastecimentos no App"
        />
        <TextField
          source="outsideTheApp"
          textAlign="center"
          label="Abastecimentos fora do App"
          sortable={false}
        />
        <FunctionField
          textAlign="center"
          label="Total de abastecimentos"
          sortable={false}
          render={record => +record.total_fillins + +(record.outsideTheApp || 0)}
        />
        <NumberField
          source="total_amount"
          label="Total em Litros"
          textAlign="center"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
        />
        <NumberField
          source="average_ticket"
          label="Ticket Médio"
          textAlign="center"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
        />
        <NumberField
          source="average_amount"
          label="Volume Médio"
          textAlign="center"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
        />
        <NumberField
          source="consider_filling_completely"
          label="Encheu Tanque"
          textAlign="center"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
        />
        <FunctionField
          source="avg_attendantrating"
          label="Nota Média"
          textAlign="center"
          emptyText="0"
          render={record => formatNumber(record.avg_attendantrating, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        />
        <NumberField
          source="count_attendantrating"
          label="Total de Avaliações"
          textAlign="center"
          emptyText="0"
        />
      </Datagrid>
    </>
  );
};

const fillinByAttendantExporter = async (data) => {
  let attendantInfo = data[0].data;
  return attendantInfo.map(attendant => {
    return {
      frentista: attendant.attendant_name || 'Sem nome',
      identfid: attendant.identfid,
      total_em_dinheiro: attendant.total_value,
      total_de_abastecimentos: attendant.total_fillins,
      total_em_litros: attendant.total_amount,
      ticket_medio: attendant.average_ticket,
      volume_medio: attendant.average_amount,
      encheu_tanque: attendant.consider_filling_completely,
      nota_media: attendant.avg_attendantrating ? Number.parseFloat(attendant.avg_attendantrating).toFixed(2) : 'Sem Nota',
      total_de_avaliacoes: attendant.count_attendantrating || "0"
    };
  });
};


const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  const { permissions } = usePermissions();
  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} />
      <DateField source="to" textAlign="center" label="Até" sortable={false} />
      <ReferenceField label="Frentista" emptyText="Todos" source="attendantId" textAlign="center" basePath="attendants" reference="attendants" link={false}>
        <FunctionField render={record => formatAttendantLabel(record)} />
      </ReferenceField>
      <ReferenceField label="Posto" emptyText="Todos" source="stationId" textAlign="center" basePath="stations" reference="stations" link={false}>
        <TextField source="name" />
      </ReferenceField>
      {permissions && permissions.includes(PERMISSIONS.TAG) &&
        <ReferenceArrayField label="Etiquetas" emptyText="Todas" source="tagIds" textAlign="center" reference='chains/chainId/tag'>
          <SingleFieldList>
            <ChipField source='name' />
          </SingleFieldList>
        </ReferenceArrayField>
      }
      <ArrayField label="Origem" emptyText="Todas" source="origin" textAlign="center">
        <SingleFieldList>
          <FunctionField render={(record) => (
            <ChipField record={{ name: FillinOrigin.translateOrigin(record) }} source="name" />
          )} />
        </SingleFieldList>
      </ArrayField>
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
    </Datagrid>
  );
};

const ReportFillinByAttendant = (props) => {
  const placeId = getPlaceId();
  const classes = useStyles();

  const listFilters = [
    <BetweenDatesInput alwaysOn />,
    <ReferenceInput
      source="attendantId"
      reference="attendants"
      sort={{ field: "name", order: "ASC" }}
      perPage={99999}
      alwaysOn
    >
      <SelectInput
        label="Frentista"
        emptyText="Todos"
        style={{ minWidth: 180 }}
        optionText={record => formatAttendantLabel(record)}
      />
    </ReferenceInput>,
    <FormDataConsumer alwaysOn>
      {({ formData }) => !placeId && (
        <ReferenceInput
          source="stationId"
          reference="stations"
          perPage={null}
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput
            label="Posto"
            emptyText="Todos"
            disabled={formData.tagIds}
            style={{ minWidth: 180 }}
            optionText={'name'}
          />
        </ReferenceInput>
      )}
    </FormDataConsumer>,
    <ReferenceArrayInput
      source="fuelIds"
      reference="fuels"
      sort={{ field: "name", order: "ASC" }}
      alwaysOn
    >
      <SelectArrayInput
        emptyText="Todos"
        label="Produto"
        style={{ minWidth: 180 }}
        optionText={'name'}
      />
    </ReferenceArrayInput>,
    <Permission permission={PERMISSIONS.TAG} alwaysOn>
      <FormDataConsumer>
        {({ formData }) => !placeId && (
          <ReferenceArrayInput
            className={classes.refArrayInput}
            source="tagIds"
            reference="chains/chainId/tag"
            sort={{ field: "name", order: "ASC" }}
            filter={{ type: TagType.station }}
          >
            <SelectArrayInput
              label="Etiqueta"
              disabled={formData.stationId}
              optionText={'name'}
            />
          </ReferenceArrayInput>
        )}
      </FormDataConsumer>
    </Permission>,
    <SelectArrayInput
      label="Origem"
      source="origin"
      choices={FillinOrigin.fillinOriginChoices}
      emptyText="Todos"
      style={{ minWidth: 160 }}
      alwaysOn
    />
  ];

  return (
    <Permission permission={PERMISSIONS.FILLIN_BY_ATTENDANT_REPORT}>
      <ReportList
        {...props}
        basePath="fillin-by-attendant"
        title="Abastecimentos por Frentista"
        resource="chains/chainId/reports/fillin-by-attendant"
        filters={listFilters}
        sort={{ field: 'attendant_name', order: 'ASC' }}
        perPage={25}
        pagination={<ListPagination />}
        filterDefaultValues={{
          stationId: placeId,
          from: moment().startOf('day').toISOString(),
          to: moment().endOf('day').toISOString()
        }}
        bulkActionButtons={false}
        exporter={false}
        titleOnPrint="Relatório de Abast. por Frentista"
        filterTablePrint={<FilterTablePrint />}
        customExporter={fillinByAttendantExporter}
        landscape
      >
        <FillinByAttendant />
      </ReportList>
    </Permission >
  );
};

export default ReportFillinByAttendant;